<template>
  <MixinWrapper
    :backgroundColor="'#FFFFFF'"
    style="padding-top: 3.8rem !important"
    id="products"
  >
    <b-row>
      <b-col sm="12">
        <div class="pt-5"></div>
      </b-col>
    </b-row>
    <div class="container">
      <b-row>
        <b-col sm="4" class="mx-auto" v-for="(i, index) in items" :key="index">
          <b-card border-variant="light" class="no-border">
            <b-img thumbnail fluid :src="i.img"></b-img>
            <b-card-text>
              {{ i.title }}
              <div class="w-75"><hr/></div>
              <div v-if="i.description!=''">
                 Fact Sheet: &#160;<b-link :href="i.description" target="_blank"
                  ><b-icon icon="download" aria-hidden="true"></b-icon>
                </b-link>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
         <b-col sm="4" class="mx-auto"></b-col>
      </b-row>
    </div>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      items: [
        {
          code: "001",
          img: "https://cdnwebsite.blob.core.windows.net/products/Immunocal.jpg",
          title: "Immunocal",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "002",
          img: "https://cdnwebsite.blob.core.windows.net/products/Immunocal Platinum.jpg",
          title: "Immunocal Platinum",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "003",
          img: "https://cdnwebsite.blob.core.windows.net/products/Immunocal Sport.jpg",
          title: "Immunocal Sport",
          description: "https://cdn.immunotec.com/docs/EN/US/Immunocal_Sport_Benefits_US_ENG.pdf",
          price: "",
          factSheet: "",
        },
        {
          code: "004",
          img: "https://cdnwebsite.blob.core.windows.net/products/Immunocal R&G.jpg",
          title: "Immunocal Booster Reds & Greens",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "005",
          img: "https://cdnwebsite.blob.core.windows.net/products/Immunocal Booster.jpg",
          title: "Immunocal Booster Energy Performance",
          description: "https://cdn.immunotec.com/docs/EN/US/Prod-BoosterEnergy-FactSheet.pdf",
          price: "",
          factSheet: "",
        },
        {
          code: "006",
          img: "https://cdnwebsite.blob.core.windows.net/products/OTG.jpg",
          title: "On the Go Shakes",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "007",
          img: "https://cdnwebsite.blob.core.windows.net/products/Magistral.jpg",
          title: "Magistral",
          description: "https://cdn.immunotec.com/docs/SP/US/Prod_Magistral_US_SP.pdf",
          price: "",
          factSheet: "",
        },
        {
          code: "008",
          img: "https://cdnwebsite.blob.core.windows.net/products/K21.jpg",
          title: "K-21",
          description: "https://media.immunotec.com/EN/US/Factsheet-k21.pdf",
          price: "",
          factSheet: "",
        },
        {
          code: "009",
          img: "https://cdnwebsite.blob.core.windows.net/products/Cogniva.jpg",
          title: "Cogniva",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "010",
          img: "https://cdnwebsite.blob.core.windows.net/products/Seren -T 200.jpg",
          title: "Seren -T 200",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "011",
          img: "https://cdnwebsite.blob.core.windows.net/products/Multi.jpg",
          title: "Multi+Resveratrol",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "012",
          img: "https://cdnwebsite.blob.core.windows.net/products/Omega.jpg",
          title: "Omega Gen V",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "013",
          img: "https://cdnwebsite.blob.core.windows.net/products/Calcium.jpg",
          title: "Calcium-D",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "014",
          img: "https://cdnwebsite.blob.core.windows.net/products/Probio 3+.jpg",
          title: "Probio 3+",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "015",
          img: "https://cdnwebsite.blob.core.windows.net/products/Contralenta.jpg",
          title: "Contralenta",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "016",
          img: "https://cdnwebsite.blob.core.windows.net/products/Toothpaste.jpg",
          title: "Toothpaste",
          description: "",
          price: "",
          factSheet: "",
        },
        {
          code: "017",
          img: "https://cdnwebsite.blob.core.windows.net/products/Derma.jpg",
          title: "Immunocal Derma",
          description: "",
          price: "",
          factSheet: "",
        },
      ],
    };
  },
  components: {
    MixinWrapper,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>