<template>
  <MixinWrapper
    :backgroundColor="'#FFFFFF'"
    style="padding-top: 3.7rem !important"
    id="wellness"
  >
    <b-row>
      <b-col sm="12">
        <b-img center fluid :src="banner1" class="w-100"></b-img>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <br />
        <br />
        <p>
          Immunotec, a pioner health-tech company, revealed a surprising
          discovery: a protein, which is found in all human cells and may hold
          the secret of the immune response, including anti-aging. That secret
          factor is a biomolecule called glutathione. Glutathione is essential
          for energy, youth preservation, and most importantly, a healthy immune
          system. It's the closest thing to the fountain of youth ever
          discovered. In fact, there is an irrefutable scientific correlation
          between aging and the reduction of glutathione in cells. Glutathione
          is the natural method to slow down the aging process and maintain good
          health.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-card-group>
          <b-card
            :img-src="img1"
            img-top
            class="no-border mx-auto"    style="max-width: 80%" 
          >
            <div
              class="text-lg-left align-top align-text-top"
            >
              <p>
                Immunotec is creating a wellness revolution, based on the simple
                philosophy of strengthening your immunity naturally!
              </p>
              <p>
                What people are calling the “health care“ industry is actually
                the “sick care” industry. Millions of people are entrapped in
                this limiting mindset and are missing out on the wellness that
                is their birthright. They need to learn that the best version of
                health isn’t achieved by treating illness and symptoms after the
                fact – but nourishing your body with specific nutrients that
                allow you to maintain a healthy immune response.
              </p>
            </div>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      banner1:
        "https://cdnwebsite.blob.core.windows.net/portfolio/BANNERS-WEB_WELLNESS_02.jpg",
      img1: "https://cdnwebsite.blob.core.windows.net/portfolio/BODEGON_HOR_02.png",
    };
  },
  components: {
    MixinWrapper,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>