<template>
  <MixinWrapper
    :backgroundColor="'#FFFFFF'"
   class="mt-5"
    id="science"
  >
   <b-row>
      <b-col sm="12">
        <b-img :src="banner1" class="w-100"></b-img>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-row>
          <b-col>
            <br />
            <span style="font-size: 39px; vertical-align: -1em !important"
              >We are proud of our&nbsp;<span class="text-title">Science</span>
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto">
        <br />
        <p class="text-center font-weight-lighter">
          The Immunotec Scientific Advisory Board (SAB) is a highly respected
          team of experts. They act in an advisory role to ensure that all
          current and future Immunotec products are backed by solid medical
          science, clinical studies, and proven results. Creating this Board
          expands Immunotec’s ability to keep up with the latest research,
          develop breakthrough new products, and serve as a liaison to medical
          and educational institutions involved in Immunocal research.
        </p>
        <p class="text-center font-weight-lighter">
          The SAB developed standards of evidence based on the pre-clinical and
          clinical studies conducted to standardize the products we offer our
          customers. This mission led them to developing our proprietary
          Immunotec Immune Strengthening Protocol™ (ISP). This ISP is the “north
          star” guiding principle for all Immunotec product development. The
          philosophy behind the ISP is simple, yet profound: Instead of
          concentrating on treating diseases and illnesses after the fact,
          Immunotec believes it’s better to nourish your body with specific
          nutrients that allow your own body to maintain a healthy immune
          response.
        </p>
        <p class="text-center font-weight-lighter">
          By assembling a diverse collection of experts in health, medicine,
          pharmacology, diet, exercise, and sports fitness – the SAB brings a
          multidisciplinary approach to wellness to help you achieve your
          optimum state of health. The Board believes that wholesome living is
          predicated on an adequate balance of healthy eating, daily physical
          activity, and optimal nutrition. As individuals do more to take care
          of themselves, they will improve their quality of life. The SAB is
          dedicated to keeping Immunotec as the preeminent provider of
          nutritional products for maintaining a healthy immune response.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" class="text-center">
        <br />
        <br />
        <span class="text-title">Science</span>
      </b-col>
      <b-col sm="12" class="text-center">
        <div>
          <span style="font-size: 38px">(SAB)</span>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="3" class="mx-auto text-center">
        <b-card border-variant="light" class="no-border">
          <b-link @click="handleShow('modal-xl', doctor1)"
            ><b-img :src="doctor1.img" rounded="circle"></b-img
          ></b-link>
          <b-card-text>
            <strong>{{ doctor1.title1 }}</strong>
            <p>{{ doctor1.description }}</p>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="3" class="mx-auto text-center">
        <b-card border-variant="light" class="no-border">
          <b-link @click="handleShow('modal-xl', doctor2)"
            ><b-img :src="doctor2.img" rounded="circle"></b-img
          ></b-link>
          <b-card-text>
            <strong>{{ doctor2.title1 }}</strong>
            <p>{{ doctor2.description }}</p>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="3" class="mx-auto text-center">
        <b-card border-variant="light" class="no-border">
          <b-link @click="handleShow('modal-xl', doctor3)"
            ><b-img :src="doctor3.img" rounded="circle"></b-img
          ></b-link>
          <b-card-text>
            <strong>{{ doctor3.title1 }}</strong>
            <p>{{ doctor3.description }}</p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="3" class="mx-auto text-center">
        <b-card border-variant="light" class="no-border">
          <b-link @click="handleShow('modal-xl', doctor4)"
            ><b-img :src="doctor4.img" rounded="circle"></b-img
          ></b-link>
          <b-card-text>
            <strong>{{ doctor4.title1 }}</strong>
            <p>{{ doctor4.description }}</p>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="3" class="mx-auto text-center">
        <b-card border-variant="light" class="no-border">
          <b-link @click="handleShow('modal-xl', doctor5)"
            ><b-img :src="doctor5.img" rounded="circle"></b-img
          ></b-link>
          <b-card-text>
            <strong>{{ doctor5.title1 }}</strong>
            <p>{{ doctor5.description }}</p>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="3" class="mx-auto text-center">
        <b-card border-variant="light" class="no-border">
          <b-link @click="handleShow('modal-xl', doctor6)"
            ><b-img :src="doctor6.img" rounded="circle"></b-img
          ></b-link>
          <b-card-text>
            <strong>{{ doctor6.title1 }}</strong>
            <p>{{ doctor6.description }}</p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
     <b-row>
      <b-col sm="3" class="mx-auto text-center">
        <b-card border-variant="light" class="no-border">
          <b-link @click="handleShow('modal-xl', doctor7)"
            ><b-img :src="doctor7.img" size="sm" rounded="circle" style="width:15rem"></b-img
          ></b-link>
          <b-card-text>
            <strong>{{ doctor7.title1 }}</strong>
            <p>{{ doctor7.description }}</p>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="3" class="mx-auto text-center">
       
      </b-col>
      <b-col sm="3" class="mx-auto text-center">
       
      </b-col>
    </b-row>

    <b-modal id="modal-xl" size="lg" centered>
      <template #modal-header="{ close }">
        <b-button size="sm" variant="light" @click="close()"> </b-button>
      </template>
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters>
          <b-col md="4">
            <b-card-img :src="modal.img" class="rounded-0"></b-card-img>
          </b-col>
          <b-col md="8">
            <b-card-body>
              <b-card-text>
                <h1>{{ modal.title2 }}</h1>
                <p>{{ modal.p1 }}</p>
                <p>{{ modal.p2 }}</p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <template #modal-footer="{ close }">
        <b-button
          variant="primary"
          size="sm"
          class="float-left"
          @click="close()"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      letter1:
        "https://static.wixstatic.com/media/8813b9_3ebd803f040e41acbbdba9c84567020a~mv2.png/v1/crop/x_41,y_0,w_686,h_369/fill/w_262,h_140,al_c,usm_0.66_1.00_0.01,enc_auto/PNGScreen%20Shot%202021-04-13%20at%201_52_edited.png",
      letter2:
        "https://static.wixstatic.com/media/8813b9_7921ec33f72a400780a4740c00e348aa~mv2.png/v1/fill/w_600,h_118,al_c,usm_0.66_1.00_0.01,enc_auto/PNGScreen%20Shot%202021-04-13%20at%201_52_edited.png",
      banner1:
        "https://cdnwebsite.blob.core.windows.net/science/BANNERS-WEB_SCIENCE_01.jpg",
      doctor1: {
        img: "https://static.wixstatic.com/media/8813b9_5aed8ee0484e4132bc4a0f1a045a3896~mv2.jpg/v1/crop/x_0,y_8,w_400,h_400/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/download.jpg",
        title1: "Jimmy Gutman, M.D.",
        title2: "Dr. Jimmy Gutman",
        description: "Chairperson",
        p1: "The author of several best-selling books on glutathione, Dr. Jimmy Gutman is arguably the world’s preeminent expert on the role of this critical substance in human health. His expert knowledge has been invaluable in bringing public awareness to bridging the gap between traditional and complementary medicine.",
        p2: "Dr. Gutman’s latest book, The Comprehensive Guide to Glutathione is considered the seminal work on glutathione research and clinical applications. Dr. Gutman taught at McGill University Medical School where his original specialty was academic emergency medicine.  He was trained at the University of Calgary and did his specialty training at Emory University in Atlanta, Georgia, where he was chief resident.  Dr. Gutman served on the Board of Directors for the Canadian Association of Emergency Medicine, and numerous other boards dealing with policy and education.   Dr. Gutman has been a scientific advisor to Immunotec for more than 25 years and continues to make strides forward in glutathione research.",
      },
      doctor2: {
        img: "https://static.wixstatic.com/media/8813b9_e157def4b54644f48b129a087ebfc0eb~mv2.jpg/v1/crop/x_0,y_8,w_400,h_400/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/download-2.jpg",
        title1: "Dr. Patricia Kongshavn, Ph.D.",
        title2: "John Molson",
        description: "Member Emeritus",
        p1: "Dr. Patricia Kongshavn was an early pioneer in the emerging field of basic immunology. Her collaboration with Dr Gustavo Bounous resulted in joint research investigating using dietary proteins to improve the immune response. Their work led to the discovery of a whey protein concentrate that enhanced immune responsiveness by raising glutathione levels and the eventual formation of Immunotec as one of the first health-tech companies in the world. ",
        p2: "Dr. Kongshavn received her honors degree in Natural Sciences at Cambridge University. She completed her master’s in Physiology and her doctoral degree in Immunology at McGill University. She was a Full Professor in the Department of Medicine at McGill University until her retirement.  She has continued as a highly valued advisor to Immunotec.",
      },
      doctor3: {
        img: "https://static.wixstatic.com/media/8813b9_d126e90fe471412d87c45246ba16b441~mv2.jpg/v1/crop/x_0,y_8,w_400,h_400/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/download-1.jpg",
        title1: "John Molson",
        title2: "John Molson",
        description: "Member Emeritus",
        p1: "A co-founder of Immunotec, John Molson has been responsible for securing high-level research partners for clinical and scientific studies. John has contributed to more than 30 medical publications and four patent applications. John is also an elite athlete, having won multiple cycling and triathlon championships. He currently holds the title of Research Ambassador for Immunotec.",
        p2: "",
      },
      doctor4: {
        img: "https://static.wixstatic.com/media/8813b9_c39543242ecd4ca69260654396d20ba4~mv2.jpg/v1/crop/x_0,y_8,w_400,h_400/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/download-3.jpg",
        title1: "Dr. Dan Linseman, Ph.D.",
        title2: "Dr. Dan Linseman",
        description: "Neuropharmacology",
        p1: "A professor at University of Denver, Dr. Dan Linseman teaches Neuropharmacology and Cell Signaling.  He received his B.S. in Biology and Chemistry his Ph.D. in Pharmacology from the University of Michigan. Dr. Linseman studies the impact of oxidative stress, the role of antioxidants, anti-neuroinflammatory, and neuroprotective properties in a variety of natural products, including Immunocal. He conducts research on neurotrauma, neurodegeneration, and neurophysiology, and is one the preeminent experts on Lou Gehrig’s disease.",
        p2: "",
      },
      doctor5: {
        img: "https://static.wixstatic.com/media/8813b9_223459d55aee4661ba197d795ff2b0c6~mv2.jpg/v1/crop/x_0,y_8,w_400,h_400/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/download-4.jpg",
        title1: "Dr. Nathan Lewis, Ph.D.",
        title2: "Dr. Nathan Lewis",
        description: "Athletic Performance",
        p1: "An expert in the area of athletic performance, Dr. Nathan Lewis specializes in biomarkers, oxidative stress in elite endurance athletes, and Overtraining Syndrome.  Dr. Lewis is the Lead Performance Nutrition Scientist for the English Institute of Sport, having worked as a senior performance nutritionist across four Olympics.",
        p2: "In addition, Dr. Lewis has extensive experience consulting to professional sports teams in the English Premier League, PGA, NBA, NHL, and MLB through his role as the Lead Performance and Clinical Nutritionist and Biomarker Scientist at the sports science and data/biomarker analytics company ORRECO.  He is also a research fellow at St Mary’s University",
      },
      doctor6: {
        img: "https://static.wixstatic.com/media/8813b9_8438540e3eba46bd96c8406b2fa93100~mv2.jpg/v1/crop/x_0,y_8,w_400,h_400/fill/w_225,h_225,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/download-5.jpg",
        title1: "Will Brink",
        title2: "Will Brink",
        description: "sports nutrition",
        p1: "Will Brink has been a researcher and writer on sports nutrition, longevity, and disease prevention, with a focus on whey protein for more than 30 years.  His expertise is in the areas of supplements, injury prevention and treatment, fitness, longevity, and bodybuilding.  Will has worked with a wide array of clients and groups, such as professional bodybuilders, other elite athletes, athletic trainers, police, and military personnel.  He has served as product and marketing consultant to many top nutritional product companies including Glanbia Nutritionals.",
        p2: "",
      },
       doctor7: {
        img: "https://cdnwebsite.blob.core.windows.net/science/Elvira María Hebert, Ph. D..jfif",
        title1: "Elvira María Hebert, Ph. D.",
        title2: "Elvira María Hebert, Ph. D.",
        description: "",
        p1: "Dr. Elvira María Hebert is Principal Investigator of the National Scientific and Technical Research Council (CONICET) at the Reference Center for Lactobacilli in Tucumán, Argentina. She is the author of more than 80 journal articles and book chapters in the field of lactic acid bacteria. Dr. Hebert received her Ph.D. degree in biochemistry from the National University of Tucumán. She has participated in different research projects in the areas of biotechnology, physiology of lactic acid bacteria, molecular microbiology, food technology, probiotic and prebiotics, and omics techniques.",
        p2: "",
      },
      modal: {
        title1: "",
        title2: "",
        img: "",
        p1: "",
        p2: "",
        description: "",
      },
    };
  },
  //
  components: {
    MixinWrapper,
  },
  methods: {
    handleShow(id, object) {
      this.modal = object;
      this.$bvModal.show(id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>