<template>
  <div>
    <HealthComponent />
  </div>
</template>

<script>
import HealthComponent from "@/components/pages/Home/Health";

export default {
  name: "Health",
  components: {
    HealthComponent,
  },
};
</script>