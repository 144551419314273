<template>
  <div>
    <b-navbar toggleable="md" type="light" fixed="top">
      <b-navbar-nav>
        <b-nav-item
          ><span class="d-block d-sm-none d-sm-block d-md-none"
            ><b-img :src="logo1" class="navbar-logo"></b-img></span
        ></b-nav-item>
      </b-navbar-nav>
      <b-navbar-brand class="d-none d-md-block mx-auto" href="#"
        ><img width="90%" :src="logo1"
      /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mx-auto text-center">
          <b-navbar-brand
            href="#/home"
            class="navbar-text nav-link"
            :active="$route.name == 'home'"
            >Home</b-navbar-brand
          >
          <b-navbar-brand
            href="#/about-us"
            class="navbar-text nav-link"
            :active="$route.name == 'about-us'"
            >About Us</b-navbar-brand
          >
          <b-navbar-brand
            href="#/service"
            class="navbar-text nav-link"
            :active="$route.name == 'service'"
            >Why Immunotec</b-navbar-brand
          >

          <b-navbar-brand
            href="#/science"
            class="navbar-text nav-link"
            :active="$route.name == 'science'"
            >Science</b-navbar-brand
          >
          <b-navbar-brand
            href="#/catalog"
            class="navbar-text nav-link"
            :active="$route.name == 'catalog'"
            >Portfolio
            <b-dropdown id="Portfolio-left" variant="link">
              <b-dropdown-item href="#/health">Health</b-dropdown-item>
              <b-dropdown-item href="#/wellness">Wellness</b-dropdown-item>
              <b-dropdown-item href="#/sport">Sport</b-dropdown-item>
              <b-dropdown-item href="#/products">Our Products</b-dropdown-item>
            </b-dropdown>
          </b-navbar-brand>

          <b-navbar-brand
            href="#/file-share"
            class="navbar-text nav-link"
            :active="$route.name == 'file-share'"
            >Library</b-navbar-brand
          >
          <b-nav-item-dropdown class="navbar-brand navbar-text nav-link">
            <template #button-content>
              {{ getUser }}
              <b-icon icon="person-circle" aria-hidden="true"></b-icon>
            </template>
            <b-dropdown-item-button v-b-toggle.sidebar-right>
              <b-icon icon="lock" aria-hidden="true"></b-icon> Change Password
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="logout">
              <b-icon icon="power" aria-hidden="true"></b-icon> Log Out
            </b-dropdown-item-button>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-sidebar
      id="sidebar-right"
      title="Change password"
      backdrop
      backdrop-variant="secondary"
      shadow
      right
    >
      <div class="px-3 py-2">
        <br />
        <br />
        <h3>Change Password</h3>
        <b-form>
          <label class="sr-only">Old password</label>
          <b-form-input
            class="form-control"
            v-model="model.oldpassword"
            required
          ></b-form-input>

          <label class="sr-only">New password</label>
          <b-form-input
            class="form-control"
            v-model="model.newpassword"
            required
          ></b-form-input>
          <br />
          <b-button type="submit" variant="primary" @click="changepassword">
            <span class="sr-only"
              ><b-spinner small v-show="loading"></b-spinner>Save</span
            > </b-button
          >&nbsp;
          <b-button v-b-toggle.sidebar-right>Cancel</b-button>
        </b-form>
        <br />
        <br />
        <b-alert variant="danger" show v-show="isError">{{ message }}</b-alert>
        <b-alert variant="success" show v-show="isSuccess">{{
          message
        }}</b-alert>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { HTTP } from "@/components/core/Services/http-services";
export default {
  data() {
    return {
      model: {
        oldpassword: "",
        newpassword: "",
      },
      loading: false,
      message: "",
      isSuccess: false,
      isError: false,
      logo: "https://cdnwebsite.blob.core.windows.net/logo/logo.png",
      logo1: "https://cdn.immunotec.com/docs/images/Immunotec-logo.svg",
      logo2: "https://cdnwebsite.blob.core.windows.net/logo/logo2.png",
    };
  },
  computed: {
    navbarBackground: function () {
      return {};
    },
    getUser() {
      return localStorage.getItem("auth_name");
    },
  },
  created() {},
  methods: {
    logout() {
      localStorage.removeItem("auth_userId");
      localStorage.removeItem("auth_login");
      localStorage.removeItem("auth_email");
      localStorage.removeItem("auth_sessionId");
      localStorage.removeItem("auth_name");
      localStorage.removeItem("access_token");
      localStorage.removeItem("loggedIn", false);
      window.localStorage.setItem("authenticated", false);
      this.$router.push("/login");
    },
    changepassword() {
      this.loading = true;
      this.isSuccess = false;
      this.isError = false;
      return HTTP.post("Auth/ChangePassword", {
        login: localStorage.getItem("auth_login"),
        oldpassword: this.model.oldpassword,
        newpassword: this.model.newpassword,
      })
        .then((response) => {
          console.log(response);
          this.loading = false;
          //  this.$router.push("/home");
          this.isSuccess = true;
          this.message = "Successfully updated the password.";
        })
        .catch((e) => {
          this.loading = false;
          this.isError = true;
          this.message = e.response.data.message;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./Navbar";
</style>
