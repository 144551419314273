<template>
  <MixinWrapper :backgroundColor="'#FFFFFF'" class="mt-5" id="library">
    <b-row>
      <b-col sm="12" class="mx-auto text-center">
        <br />
        <br />
        <span style="font-size: 39px; vertical-align: -1em !important"
          >Resource&nbsp;<span class="text-title">library</span></span
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto">
        <b-card>
          <template #header> <h1>PDF</h1> </template>
          <b-card-text>
            <b-table
              :items="pdf"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              show-empty
              small
              responsive
            >
              <template #cell(name)="data">
                {{ data.value }}
              </template>

              <template #cell(preview)="data">
                <b-img
                  :src="data.item.preview"
                  fluid
                  style="max-width: 20%"
                ></b-img>
              </template>

              <template #cell(download)="data">
                <b-link :href="data.item.link" target="_blank"
                  ><b-icon icon="download" aria-hidden="true"></b-icon>
                </b-link>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto">
        <hr class="mr-4" />
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto">
        <b-card>
          <template #header> <h1>Video</h1> </template>
          <b-card-text>
            <b-table
              :items="videos"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              show-empty
              small
            >
              <template #cell(name)="data">
                {{ data.value }}
              </template>

              <template #cell(preview)="data">
                <iframe
                  :src="data.item.preview"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen
                  style="max-width: 90%"
                ></iframe>
              </template>

              <template #cell(download)="data">
                <b-link :href="data.item.link" target="_blank"
                  ><b-icon icon="download" aria-hidden="true"></b-icon>
                </b-link>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto">
        <hr class="mr-4" />
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto">
        <b-card>
          <template #header> <h1>Social Media</h1> </template>
          <b-card-text>
            <b-table
              :items="social"
              :fields="fields2"
              :sort-by.sync="sortBySocial"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              show-empty
              small
              responsive
            >
              <template #cell(name)="data">
                {{ data.value }}
              </template>

              <template #cell(preview)="data">
                <b-img
                  :src="data.item.preview"
                  fluid
                  style="max-width: 40%"
                ></b-img>
              </template>

              <template #cell(download)="data">
                <ShareNetwork
                  v-for="network in networks"
                  :network="network.name"
                  :key="network.name"
                  :url="data.item.link"
                  :title="data.item.name"
                  :description="data.item.name"
                >
                  <b-img
                    fluid
                    v-if="network.isImg"
                    :src="network.icon"
                    aria-hidden="true"
                    alt="icon"
                    role="img"
                    focusable="false"
                    style="width: 10%"
                  ></b-img>
                  &nbsp;
                  <b-icon
                    v-if="!network.isImg"
                    :icon="network.icon"
                    aria-hidden="true"
                    alt="icon"
                    role="img"
                    focusable="false"
                  ></b-icon>
                </ShareNetwork>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  components: {
    MixinWrapper,
  },
  data() {
    return {
      sortBy: "name",
      sortDesc: false,
      sortBySocial: "id",
      filter: "",
      fields: [
        { key: "name", label: "Name" },
        { key: "preview", label: "Preview" },
        { key: "download", label: "Download" },
      ],
      fields2: [
        { key: "name", label: "Name" },
        { key: "preview", label: "Preview" },
        { key: "download", label: "Share" },
      ],
      pdf: [
        {
          id: "1",
          name: "Compendium of Pharmaceuticals and Specialties (CPS) ",
          link: "https://cdn.immunotec.com/docs/EN/US/pro-immunocal-CPS-EN.pdf",
          preview:
            "https://cdn.immunotec.com/docs/EN/US/images/pro-immunocal-CPS-EN.png",
        },
        {
          id: "2",
          name: "Physicians’ Desk Reference (PDR)",
          link: "https://cdn.immunotec.com/docs/EN/US/pro-immunocal-PDR-EN.pdf",
          preview:
            "https://cdn.immunotec.com/docs/EN/US/images/pro-immunocal-PDR-EN.png",
        },
        {
          id: "3",
          name: "Immunocal Sport fact sheet",
          link: "https://cdn.immunotec.com/docs/EN/US/Immunocal_Sport_Benefits_US_ENG.pdf",
          preview:
            "https://cdn.immunotec.com/docs/EN/US/images/Immunocal_Sport_Benefits_US_ENG.png",
        },
        {
          id: "4",
          name: "K21 fact sheet",
          link: "https://cdn.immunotec.com/docs/EN/CA/prod-factsheet-K21Plus-CA-EN.pdf",
          preview: "https://cdn.immunotec.com/docs/EN/CA/images/prod-factsheet-K21Plus-CA-EN.jpg",
        },
        {
          id: "5",
          name: "Booster energy and Performance fact sheet",
          link: "https://cdn.immunotec.com/docs/EN/US/Prod-BoosterEnergy-FactSheet.pdf",
          preview:
            "https://cdn.immunotec.com/docs/EN/US/images/Prod-BoosterEnergy-FactSheet.png",
        },
        {
          id: "6",
          name: "Studies",
          link: "https://cdn.immunotec.com/docs/EN/Immunocal-Published-Studies-EN1.pdf",
          preview:
            "https://cdn.immunotec.com/docs/EN/images/Immunocal-Published-Studies-EN1.png",
        },
        {
          id: "7",
          name: "Magistral fact sheet ",
          link: "https://cdn.immunotec.com/docs/SP/US/Prod_Magistral_US_SP.pdf",
          preview:
            "https://cdn.immunotec.com/docs/SP/US/images/Prod_Magistral_US_SP.png",
        },
      ],
      videos: [
        {
          id: "1",
          name: "Immunocal Platinum",
          link: "https://player.vimeo.com/video/668684873?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/668684873?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "2",
          name: "Immunocal Booster | Green & Reds",
          link: "https://player.vimeo.com/video/668687262?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/668687262?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "3",
          name: "Immunocal Booster Energy Performance | Feel the Boost",
          link: "https://player.vimeo.com/video/668693211?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/668693211?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "4",
          name: "Cogniva",
          link: "https://player.vimeo.com/video/670754608?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/670754608?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "5",
          name: "Immunocal | Dr. Hugo Palafox",
          link: "https://player.vimeo.com/video/668679705?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/668679705?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "6",
          name: "Wellness Revolution",
          link: "https://player.vimeo.com/video/661099244?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/661099244?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "7",
          name: "Discover Immunotec",
          link: "https://player.vimeo.com/video/668669339?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/668669339?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "8",
          name: "Derma",
          link: "https://player.vimeo.com/video/670754789?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/670754789?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "9",
          name: "Omega Gen V",
          link: "https://player.vimeo.com/video/670754853?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/670754853?autoplay=1&loop=1&autopause=0&muted=1",
        },
        {
          id: "10",
          name: "Magistral",
          link: "https://player.vimeo.com/video/717925725?autoplay=1&loop=1&autopause=0",
          preview:
            "https://player.vimeo.com/video/717925725?autoplay=1&loop=1&autopause=0&muted=1",
        },
      ],
      social: [
        {
          id: "1",
          name: "Immunocal  Booster  Energy/Performance        ",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/e32fc818-cc6f-49bf-a56a-3cb15603c8a9.JPG",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/e32fc818-cc6f-49bf-a56a-3cb15603c8a9.JPG",
        },
        {
          id: "2",
          name: "Immunocal box and Shaker cup",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/DSC_2103.jpg",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/DSC_2103.jpg",
        },
        {
          id: "3",
          name: "Immunocal  box  and  Shaker  cup  #2      ",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/DSC_2107.jpg",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/DSC_2107.jpg",
        },
        {
          id: "4",
          name: "Immunocal pouch and Shaker cup",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/DSC_2109.jpg",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/DSC_2109.jpg",
        },
        {
          id: "5",
          name: "K-21 ",
          link: "https://cdn.immunotec.com/docs/EN/CA/prod-factsheet-K21Plus-CA-EN.pdf",
          preview:
            "https://cdn.immunotec.com/docs/EN/CA/images/prod-factsheet-K21Plus-CA-EN.jpg",
        },
        {
          id: "6",
          name: "Immunocal Reds and Greens    ",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/immunocal-R6G.png",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/immunocal-R6G.png",
        },
        {
          id: "7",
          name: "Immunocal box, Shaker cup and Shirt ",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/immunocal-wahrehouse.png",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/immunocal-wahrehouse.png",
        },
        {
          id: "8",
          name: "Immunocal Sport  ",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/Immunocal Sport.png",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/Immunocal Sport.png",
        },
        {
          id: "9",
          name: "Sport Pouch",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/Immunocal sport pouch.png",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/Immunocal sport pouch.png",
        },
        {
          id: "10",
          name: "Sport pouch  and Shaker cup",
          link: "https://cdnwebsite.blob.core.windows.net/socialmedia/Licuado sport.png",
          preview:
            "https://cdnwebsite.blob.core.windows.net/socialmedia/Licuado sport.png",
        },
      ],
      sharing: {
        title:
          "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description:
          'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: "The hot reload is so fast it's near instant. - Evan You",
        hashtags: "vuejs,vite,javascript",
        twitterUser: "youyuxi",
      },
      networks: [
        { name: "Email", icon: "envelope", isImg: false },
        { name: "Facebook", icon: "facebook", isImg: false },
        { name: "LinkedIn", icon: "linkedin", isImg: false },
        //{ name: 'Messenger', icon: 'messenger'},
        //{ name: 'Skype', icon: 'skype' },
        //{ name: 'SMS', icon: 'chat-left-dots' },
        //{ name: 'Telegram', icon: 'telegram'},
        //{ name: 'Twitter', icon: 'twitter' },
        {
          name: "Whatsapp",
          icon: "https://logodownload.org/wp-content/uploads/2015/04/Whatsapp-logo-vetor.png",
          isImg: true,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import "./index";
</style>