<template>
  <div>
    <CatalogComponent />
  </div>
</template>

<script>
import CatalogComponent from "@/components/pages/Home/Catalog";

export default {
  name: "Catalog",
  components: {
   CatalogComponent,
  },
};
</script>