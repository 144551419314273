<template>
    <MixinWrapper :backgroundColor="'#FFFFFF'" id="login">
        <div class="login-page">
            <transition name="fade">
                <div v-if="!registerActive" class="wallpaper-login"></div>
            </transition>
            <div class="wallpaper-register"></div>

            <div class="container">
                <b-card no-body class="overflow-hidden">
                    <b-row no-gutters>
                        <b-col md="6">
                            <b-jumbotron>
                                <br />
                                <br />
                                <br />
                                <br />
                                <b-img :src="logo1" fluid></b-img>
                            </b-jumbotron>
                        </b-col>
                        <b-col md="6">
                            <b-card-body>
                                <b-card-text>
                                    <h1>Sign In</h1>
                                    <br />
                                    <br />
                                    <form @submit.prevent="doLogin">
                                        <input v-model="model.login"
                                               type="email"
                                               class="form-control"
                                               placeholder="Email"
                                               required />
                                        <br />
                                        <input v-model="model.password"
                                               type="password"
                                               class="form-control"
                                               placeholder="Password"
                                               required />
                                        <br />
                                        <b-button type="submit" variant="primary">
                                            <span class="sr-only">
                                                <b-spinner small v-show="loading"></b-spinner>Login
                                            </span>
                                        </b-button>
                                        <br />
                                        <br />
                                        <p>
                                            Don't have an account?
                                            <a href="#/register/new">Sign up here</a>
                                        </p>
                                    </form>
                                </b-card-text>
                            </b-card-body>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </div>
    </MixinWrapper>
</template>

<script>
    import MixinWrapper from "@/components/mixin/MixinWrapper";
    import { HTTP } from "@/components/core/Services/http-services";
    export default {
        name: "Login",
        components: {
            MixinWrapper,
        },
        data() {
            return {
                registerActive: false,
                loading: false,
                model: {
                    login: "",
                    password: "",
                },
                logo: "https://cdnwebsite.blob.core.windows.net/logo/logo.png",
                logo1: "https://cdn.immunotec.com/docs/images/Immunotec-logo.svg",
                img: "https://cdnwebsite.blob.core.windows.net/logo/login.png",
            };
        },
        methods: {
            doLogin() {
                this.loading = true;
                return HTTP.post("Auth/Login", {
                    login: this.model.login,
                    password: this.model.password,
                })
                    .then((response) => {
                        this.loading = false;
                        localStorage.setItem("name", response.data.name);
                        localStorage.setItem("userId", response.data.userId);
                        localStorage.setItem("email", response.data.email);
                        localStorage.setItem("login", response.data.login);
                        localStorage.setItem("_Token", response.data._Token);
                        localStorage.setItem("sessionId", response.data.sessionId);
                        localStorage.setItem("loggedIn", true);
                        window.localStorage.setItem("authenticated", true);
                        if (this.$route.name !== "/home") {
                            this.$router.push("/home");
                        }
                    })
                    .catch((e) => {
                        this.loading = false;
                        this.$toasted.error(e.response.data.message);
                    });
            },
            doRegister() { },
        },
    };
</script>
<style>
    .login-page {
        align-items: center;
        display: flex;
        height: 100vh;
    }

    .wallpaper-login {
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .wallpaper-register {
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    h1 {
        margin-bottom: 1.5rem;
    }
</style>