<template>
  <div>
    <SportComponent />
  </div>
</template>

<script>
import SportComponent from "@/components/pages/Home/Sport";

export default {
  name: "Sport",
  components: {
    SportComponent,
  },
};
</script>