<template>
  <MixinWrapper
    :backgroundColor="'#FFFFFF'"
    style="padding-top: 3.6rem !important"
    id="health"
  >
    <b-row>
      <b-col sm="12">
        <div>
          <b-img center fluid :src="banner1" class="w-100"></b-img>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <br />
        <br />
        <p>
          Immunotec, a Health-Tech Pioneer company. We have developed the
          proprietary Immune Strengthening Protocol or ISP. The philosophy
          behind the ISP is simple, yet profound: Instead of concentrating on
          treating diseases and illnesses after the fact, Immunotec believes
          it's better to nourish your body with specific nutrients that allow
          you to maintain a healthy immune response. This ISP is the “north
          star” guiding principle for all Immunotec product development.
        </p>
        <p>
          Immunocal, our flagship, is backed by more than 45 years of research
          and 25 years in the market. Over eighty published scientific and
          medical articles attest to its remarkable credibility. Immunocal has
          also achieved global recognition through patents issued in the U.S.,
          Canada, and 26 other countries around the world. The philosophy behind
          the ISP is simple, yet profound: Instead of concentrating on treating
          diseases and illnesses after the fact, Immunotec believes it's better
          to nourish your body with specific nutrients that allow you to
          maintain a healthy immune response. This ISP is the “north star”
          guiding principle for all Immunotec product development.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-card-group>
          <b-card border-variant="light" class="no-border mx-auto"    style="max-width: 80%" :img-src="img1" img-top>
            <div
              class="text-lg-left align-top align-text-top"  style="margin: -4rem 0 0 10px;float:left;"
            >
              <p>
                You can take it alone or combined with any of our other
                products.
              </p>
              <p>
                Booster Red & Greens is the perfect complement to Immunocal.
                Immunocal provides the building blocks for the production of
                glutathione, your body's master antioxidant.
              </p>
              <p>
                The Booster optimizes the performance of the glutahtione that
                Immunocal helps your body produce.
              </p>
            </div>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      banner1:
        "https://cdnwebsite.blob.core.windows.net/portfolio/BANNERS-WEB_HEALTH_01.jpg",
      img1: "https://cdnwebsite.blob.core.windows.net/portfolio/BODEGON_HOR_02.jpg",
    };
  },
  components: {
    MixinWrapper,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>