<template>
  <div>
    <ContactComponent />
  </div>
</template>

<script>
import ContactComponent from "@/components/pages/Home/About";

export default {
  name: "About",
  components: {
    ContactComponent,
  },
};
</script>