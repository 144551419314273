<template>
    <div id="layout" :style="{background: backgroundColor}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['backgroundColor'],
}
</script>

<style lang="scss">
</style>
