<template>
<div id="app">
    <router-view/>
    </div>
</template>


<script>
    export default {
        name: "app",
        created() {
            if (localStorage.getItem("authenticated") === "false" || localStorage.getItem("authenticated")=== null) {
                this.$router.push("/login");
            }

            if (localStorage.getItem("authenticated") === "true") {
                this.$router.push("/home");
            }
        },
    };
</script>