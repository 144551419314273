import Vue from "vue";
import VueRouter from "vue-router";
import LayoutPage from "@/components/layouts/layout/Layout";
import HomePage from "../pages/Home";
import AboutPage from "../pages/About";
import WhyPage from "../pages/Why";
import SciencePage from "../pages/Science";
import LibraryPage from "../pages/Library";
import HealthPage from "../pages/Health";
import WellnessPage from "../pages/Wellness";
import SportPage from "../pages/Sport";
import ProductsPage from "../pages/Products";
import CatalogPage from "../pages/Catalog";
import LoginPage from "../pages/Login";
import RegisterPage from "../pages/Register";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: '/register/new',
      name: 'register',
      component: RegisterPage,
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
    },
    {
      path: "/",
      redirect: "login",
      component: LayoutPage,
      children: [
        {
          path: "/home",
          name: "home",
          component: HomePage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/about-us",
          name: "about-us",
          component: AboutPage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/service",
          name: "service",
          component: WhyPage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/science",
          name: "science",
          component: SciencePage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/file-share",
          name: "file-share",
          component: LibraryPage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/catalog",
          name: "catalog",
          component: CatalogPage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/health",
          name: "health",
          component: HealthPage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/wellness",
          name: "wellness",
          component: WellnessPage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/sport",
          name: "sport",
          component: SportPage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
        {
          path: "/products",
          name: "products",
          component: ProductsPage,
          meta: {
            requiresAuth: true
          },
          beforeEnter: CheckPermission
        },
      ],
    },
  ],
});

function CheckPermission(to, from, next) 
{
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && localStorage.getItem("authenticated") === "false")
  {
    next('/login');
  }
  else{
    next();
  }
}

