import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faArrowRight, faBicycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import feather from 'vue-icon';
import router from './router'
import VueSocialSharing from 'vue-social-sharing'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
library.add([faSearch, faArrowRight, faBicycle]);
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Toasted from 'vue-toasted';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(feather, 'v-icon');
Vue.use(Toasted, { duration: 10000 });
Vue.use(VueSocialSharing);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
