<template>
  <div>
    <b-row>
      <b-col sm="12" class="mx-auto text-center">
      <!-- Google Tag Manager (noscript) -->
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KH7LMV7"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        <!-- End Google Tag Manager (noscript) -->
        <div class="h3 mt-2">
          <b-link href="https://www.facebook.com/Immunotec" target="_blank" ><b-icon icon="facebook" aria-hidden="true"> </b-icon></b-link>&#160;
          <b-link href="https://www.youtube.com/user/CorpImmunotec" target="_blank"><b-icon icon="youtube" aria-hidden="true"> </b-icon></b-link>&#160;
          <b-link href="https://blog.immunotec.com/" style="text-decoration:none;"  target="_blank">B</b-link>&#160;
          <b-link href="https://www.instagram.com/immunotec/"  target="_blank"><b-icon icon="instagram" aria-hidden="true"> </b-icon></b-link>&#160;
        </div>
      </b-col>
    </b-row>
     <b-row class="footer">
      <b-col sm="12" class="mx-auto text-center">
          <img
          src="https://static.wixstatic.com/media/8813b9_4aafbac1d5184875b95d6c4885714d5b~mv2.png/v1/fill/w_140,h_65,al_c,usm_0.66_1.00_0.01,enc_auto/Imm%20Logo%20White.png"
        />
      </b-col>
       <b-col sm="12" class="mx-auto text-center">
           1780 Hughes Landing Blvd. Suite 1175 The Woodlands, TX 77380
      </b-col>
       <b-col sm="12" class="mx-auto text-center">
         © 2022 Immunotec Research Inc.
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {
  },
  data() {
    return {
      logo: "https://cdnwebsite.blob.core.windows.net/logo/logo.png",
      logo1: "https://cdn.immunotec.com/docs/images/Immunotec-logo.svg",
      img: "https://cdnwebsite.blob.core.windows.net/logo/login.png",
    };
  },
  methods: {
   
  },
};
</script>

<style lang="scss" scoped>
@import "./Footer";
ul {
  width: 100%;
  text-align: center;
}
li {
  display: block;
  float: left;
  color: white;
  margin-right: 10px;
}
</style>