<template>
  <MixinWrapper
    :backgroundColor="'#FFFFFF'"
  class="mt-5"
    id="about"
  >
    <b-row>
      <b-col sm="12">
        <b-img :src="banner1"  class="w-100"  center fluid ></b-img>
      </b-col>
    </b-row>

    <b-row class="pt-3">
      <b-col sm="8" class="mx-auto text-center text-home">
          <strong class="color-text"
            >The Health-Tech Firm That is Disrupting Wellness…
          </strong>
          The single most vital element of good health is arguably your immune
          system – because it’s your body’s way to protect itself. Research over
          the last 50 years led scientists to a stunning discovery: A
          little-known protein contained in all human cells that supports immune
          response, even anti-aging.
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <br />
        <br />
        <b-card
          overlay
          :img-src="banner2"
          img-alt="Outdoor Fitness"
          text-variant="black"
          style="max-width: 40rem"
          class="mx-auto"
        >
          <b-row>
            <b-col sm="8">
              <p
                class="text-lg-left"
                style="font-size: 20px; padding-top: 20%; color: #ffffff"
              >
                Now Immunotec is bringing this immunity breakthought to the
                world...
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <br />
        <br />
        <b-card
          border-variant="light"
          no-body
          class="overflow-hidden mx-auto no-border"
          style="max-width: 940px"
        >
          <b-row no-gutters>
            <b-col md="6">
              <b-card-body>
                <b-card-text>
                  <p>
                    The story begins in the 1960s, when Italian surgeon Gustavo
                    Bounous became intrigued with the vastly different outcomes
                    of recovery for post-surgical patients. Dr. Bounous posited
                    that the difference was caused before the surgeries ever
                    took place – determined by the patients’ nutritional
                    profile. He sought to discover which specific nutrients
                    impacted healing and recovery the most. Around that same
                    time, Dr. Patricia Kongshavn was pioneering exploration in
                    the emerging field of clinical immunology at McGill
                    University in Montreal. Dr. Kongshavn was focused on
                    increasing knowledge of the immune system and the genetics
                    of natural resistance. The two doctors began collaborating
                    on research that would become a lifelong quest for them both
                    – the enhancement of immune function. Their studies led them
                    to focus on glutathione, a little-known biomolecule
                    contained in all human cells that may contain the secret of
                    immune response, even anti-aging.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-col>
            <b-col md="6">
              <b-card-img :src="doctor1"></b-card-img>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <br />
        <br />
        <b-card
          border-variant="light"
          no-body
          class="overflow-hidden mx-auto no-border"
          style="max-width: 940px"
        >
          <b-row no-gutters>
            <b-col md="6">
              <b-card-img :src="doctor2"></b-card-img>
            </b-col>
            <b-col md="6">
              <b-card-body>
                <b-card-text>
                  <p>
                    Glutathione was first discovered by a French researcher in
                    1888, but its breakthrough potential was not discovered
                    until Doctors Bounous and Kongshavn discovered a safe,
                    natural, and drug-free way to effectively raise your
                    glutathione levels. Their discovery, supported by numerous
                    studies over the last 30 years, revealed that glutathione is
                    responsible for bolstering our defenses against some of the
                    largest threats facing our well-being today.
                  </p>

                  <p>
                    Glutathione, present in every cell in your body, is now
                    considered the “master antioxidant.” Dr. Bounous was the
                    first to show how raising glutathione levels could increase
                    lifespan. To further their research, in 1996 Drs Bounous and
                    Kongshavn launched one of the first health-tech startups.
                    They chose the direct selling business model and Immunotec
                    was born.
                  </p>

                  <p>
                    Dr Bounous died in 2011 at the age of 83, but the company he
                    helped co-found continued developing breakthrough wellness
                    products and developed a small but fiercely loyal following
                    of customers. The Immunotec products were one of the best
                    kept secrets in the wellness community.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <br />
        <br />
        <div>
          <b-card
            border-variant="light"
            overlay
            :img-src="banner3"
            img-alt="Man Looking Out the Window"
            text-variant="white"
            style="max-width: 40rem"
            class="mx-auto no-border"
          >
            <b-card-text>
              <div
                class="text-lg-left align-bottom align-text-bottom"
                style="font-size: 24px; padding-top: 31%"
              >
                All that began to change in 2017...
              </div>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <br />
        <br />
        <b-card
          border-variant="light"
          no-body
          class="overflow-hidden mx-auto no-border"
          style="max-width: 940px "
        >
          <b-row no-gutters>
            <b-col md="6">
              <b-card-img :src="doctor3"></b-card-img>
            </b-col>
            <b-col md="6">
              <b-card-body>
                <b-card-text>
                  <p>
                    Inside the company, there was a great desire to explore new
                    product development and expand the wellness message to more
                    countries around the world. But some founding shareholders
                    wanted to monetize their interests in estate planning.
                  </p>

                  <p>
                    Riding to the rescue was renowned entrepreneur Mauricio
                    Domenzain. Mauricio brought extensive expertise in product
                    development, marketing, and direct selling. Mauricio was
                    passionate about both the immune system research and the
                    product line Immunotec was developing. He was determined to
                    create a global movement to expand awareness of the role
                    nutrition plays in building overall wellness.
                  </p>

                  <p>
                    That vision came into reality when Mauricio led an
                    investment group to take the company private, remove
                    earnings pressure, and free up the Immunotec scientists to
                    focus on nothing but more cutting-edge research on the
                    immune system. As CEO, Mauricio and his new team brought
                    tremendous resources and experience, to allow Immunotec to
                    continue aggressive investigation, product development, and
                    international expansion. A state-of-the-art facility to
                    produce the signature product Immunocal opened in 2021, and
                    customers receive timely shipment of their orders from a
                    network of fulfillment centers in the U.S., Canada, Mexico,
                    Central America, Europe, and South America.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <br />
        <br />
        <b-card
          no-body
          border-variant="light"
          class="overflow-hidden mx-auto no-border"
          style="max-width: 940px"
        >
          <b-row no-gutters>
            <b-col md="6">
              <b-card-body>
                <b-card-text>
                  <p>
                    Immunotec is dedicated to the philosophy of living better
                    and believes you achieve this by providing your body optimal
                    nutrition, allowing it to maintain wellness naturally. Under
                    Mauricio’s leadership, the company developed the proprietary
                    Immunotec Immune Strengthening Protocol ™(ISP). The
                    philosophy behind the ISP is simple, yet profound: Instead
                    of concentrating on treating diseases and illnesses after
                    the fact, Immunotec believes it's better to nourish your
                    body with specific nutrients that allow your own body to
                    maintain a healthy immune response.
                  </p>

                  <p>
                    This ISP is the “north star” guiding principle for all
                    Immunotec product development. The entire Immunotec product
                    line is designed to help your own body strengthen its own
                    natural immune response.
                  </p>

                  <p>
                    Immunotec is continually innovating in wellness research,
                    product development, and growing the network of independent
                    consultants. The company is now operating in a dozen
                    countries, with new markets opening annually. Today,
                    Immunotec products are widely considered as the preeminent
                    nutritional sustenance for maintaining a strong immune
                    system.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-col>
            <b-col md="6">
              <b-card-img :src="doctor4" class="mr-3"></b-card-img>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <span style="font-size: 39px; vertical-align: -1em !important"
          >Proud of our&nbsp;<span class="text-title">Facilities</span>
        </span>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <p>
          We’re investing millions of dollars to ensure the most effective
          products for our customers, and lucrative business opportunity for our
          Consultants. In 2021 we opened our new state-of-the-art research and
          production facility to handle the ever-increasing demand for our
          products. This plant guarantees the highest levels of safety, potency,
          and formula consistency, so you know you’re getting the perfectly
          blended, quality product every time.
        </p>
        <br />
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="10" class="mx-auto">
        <div style="padding:40.25% 0 0 0; position: relative">
          <iframe
            :src="video1"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </b-col>
    </b-row>

  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      banner1: "https://cdnwebsite.blob.core.windows.net/about/BANNERS-WEB_ABOUT-US_01.jpg",
      banner2: "https://cdnwebsite.blob.core.windows.net/about/IMG2_R1.png",
      banner3: "https://cdnwebsite.blob.core.windows.net/about/IMG3_R1.png",
      banner4: "https://cdnwebsite.blob.core.windows.net/about/banner2_R1.png",
      doctor1:
        "https://static.wixstatic.com/media/8813b9_2c378fb8df52483f8f1b605113de31aa~mv2.jpg/v1/fill/w_483,h_501,al_c,lg_1,q_80,enc_auto/download.jpg",
      doctor2:
        "https://static.wixstatic.com/media/8813b9_1d61db164a2343a88b1be1fae4f2548b~mv2.jpg/v1/fill/w_483,h_501,al_c,lg_1,q_80,enc_auto/download-1.jpg",
      doctor3:
        "https://static.wixstatic.com/media/8813b9_83439c2cbccf460897e0fbbb1687adf0~mv2.jpg/v1/fill/w_483,h_706,al_c,lg_1,q_80,enc_auto/download-2.jpg",
      doctor4:
        "https://static.wixstatic.com/media/8813b9_fd5e3275f4ab40b3ac12ddb431929b75~mv2.jpg/v1/fill/w_578,h_354,al_c,lg_1,q_80,enc_auto/download-3.jpg",
      video1: "https://player.vimeo.com/video/692282859?autoplay=1&loop=1&autopause=0&muted=1&title=0&byline=0&portrait=0&controls=1",
    };
  },
  components: {
    MixinWrapper,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>