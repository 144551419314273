<template>
  <MixinWrapper :backgroundColor="'#FFFFFF'" class="pt-5" id="register">
    <div>
      <div class="wallpaper-register"></div>
      <div class="container mx-auto">
        <b-card no-body class="overflow-hidden">
          <b-card-body>
            <b-row>
              <b-col sm="6"> <b-jumbotron>
                <br />
                <br />
                <br />
                <br />
                <b-img :src="logo1" fluid></b-img>
              </b-jumbotron></b-col>
              <b-col sm="6">
                <b-card-text>
                   <h1>Create Account</h1>
                  <b-form @submit="onSubmit">
                    <b-form-group
                      id="input-group-2"
                      label="Name:"
                      label-for="input-2"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="form.name"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="input-group-1"
                      label="Email:"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="form.email"
                        type="email"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <!--b-form-group
                      id="input-group-2"
                      label="Login:"
                      label-for="input-2"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="form.login"
                        required
                      ></b-form-input>
                    </!--b-form-group>

                      <b-form-group
                      id="input-group-2"
                      label="Password:"
                      label-for="input-2"
                    >
                      <b-form-input
                        id="input-2"
                        type="password"
                        v-model="form.password"
                        required
                      ></b-form-input>
                    </b-form-group-->
                    <br/>
                    <b-button type="submit" variant="primary">Submit <b-spinner small v-show="loading"></b-spinner
                        ></b-button>&nbsp;
                    <b-button type="button" variant="danger" @click="onReset">Cancel</b-button>
                  </b-form>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";
import { HTTP } from "@/components/core/Services/http-services";
export default {
  name: "Login",
  components: {
    MixinWrapper,
  },
  data() {
    return {
      loading: false,
      form: {
        email: "",
        name: "",
        login: "",
        password: "",
      },
      logo1: "https://cdn.immunotec.com/docs/images/Immunotec-logo.svg",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
       this.loading = true;
       this.form.login=this.form.email;
      return HTTP.post("Auth/Register", this.form)
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.form.password=response.data.data.p;
          this.doLogin();
        })
        .catch((e) => {
          this.loading = false;
          this.$toasted.error(e.response.data.message);
        });
    },
    onReset() {
     this.$router.push("/login");
    },
    doLogin() {
      this.loading = true;
      return HTTP.post("Auth/Login", {
        login: this.form.login,
        password: this.form.password,
      })
        .then((response) => {
          this.loading = false;
          localStorage.setItem("auth_userId", response.data.data.userId);
          localStorage.setItem("auth_login", response.data.data.login);
          localStorage.setItem("auth_email", response.data.data.email);
          localStorage.setItem("auth_name", response.data.data.name);
          localStorage.setItem("access_token", response.data.data.token);
          localStorage.setItem("loggedIn", true);
          window.localStorage.setItem("authenticated", true);
          this.form.name='';
          this.form.email='';
          this.form.login='';
          this.form.password='';
          if (this.$route.name !== "/home") {
            this.$router.push("/home");
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toasted.error(e.response.data.message);
        });
    },
  },
};
</script>
<style>
.wallpaper-register {
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
</style>