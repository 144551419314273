<template>
  <MixinWrapper :backgroundColor="'#FFFFFF'"  class="mt-5" id="service">
    <b-row>
      <b-col sm="12">
        <div>
          <b-img :src="banner1" class="w-100"  center fluid  ></b-img>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" class="mx-auto text-center">
        <br>
        <br>
        <p class="h1">Why we are the best food supplement partner?</p>
      </b-col>
    </b-row>
     
    <b-row>
      <b-col sm="6" class="mx-auto mt-5">
          <p class="text-center font-weight-lighter">
          We are the Health-Tech Firm that is disrupting Wellness.
        </p>
        <p class="text-center font-weight-lighter">
          Many natural
          products are available that claim to help your immune system, but very
          few have undergone the rigorous testing and published human trials
          that Immunotec has accomplished over the years.
        </p>
         <p class="text-center font-weight-lighter">
          Immunocal, our
          flagship is backed by more that 45 years of research and and 25 years
          in the market. Over eighty published scientific and medical articles
          attest to its remarkable credibility. Immunocal has also achieved
          global recognition through patents issued in the U.S., Canada, and 26
          other countries around the world.
        </p>
        <p class="text-center font-weight-lighter">
          Health, wellness, fitness and energy are at the beating heart of
          today’s ever-growing lifestyle.
        </p>
      </b-col>
    </b-row>
    <b-row  class="mx-auto text-center">
      <b-col sm="12">
        <br />
        <br />
         <div>
          <b-img
            center
            fluid
            :src="banner2"
             class="mx-auto h-75 w-50"
          ></b-img>
        </div>
      </b-col>
    </b-row>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      banner1:"https://cdnwebsite.blob.core.windows.net/why/BANNERS-WEB_WHY-IMMUNOTEC_02.jpg",
      banner2:"https://cdnwebsite.blob.core.windows.net/why/Img1_2.png",
    };
  },
  components: {
    MixinWrapper,
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>