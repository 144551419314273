<template>
  <MixinWrapper
    :backgroundColor="'#FFFFFF'"
    id="home"
  >
  <b-row class="d-block d-sm-none d-sm-block d-md-none">
      <b-col sm="12" class="mx-auto text-center">
        <span> <br>
        <br></span>
      </b-col>
  </b-row>
    <b-row>
      <b-col sm="12" class="mx-auto text-center">
        <div style="padding: 56.25% 0 0 0; position: relative;border:0px solid red; top:calc(0.375rem - 2vw);">
          <iframe
            :src="video1"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height:100%;
            "
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </b-col>
    </b-row>

   <b-row class="pt-3">
      <b-col sm="8" class="mx-auto text-center text-home">
          We are the <strong>Health-Tech Firm</strong> that is disrupting
          Wellness… We are constantly innovating, to produce the most effective
          wellness products. Our products are widely considered as the
          preeminent nutritional sustenance for maintaining a healthy immune
          response.
          <strong>If you care about your wellness, we’ve </strong>
          <strong>got you covered.</strong>
          <br/>
          <br/>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="10" class="mx-auto">
        <div style="padding:40.25% 0 0 0; position: relative">
          <iframe
            :src="video2"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="4">
        <br />
        <b-card overlay :img-src="block1">
          <b-card-text>
            <b-card class="text-center" text-variant="black">
              <b-card-text>
                <h2>Hello, My name is Immunocal</h2>
                Immunotec's flagship product is and Immunocal is a specially
                formulated protein derived from whey. It contains glutathione
                building blocks.
              </b-card-text>
            </b-card>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="4">
        <br />
        <b-card overlay :img-src="block2">
          <b-card-text>
            <b-card class="text-center" text-variant="black">
              <b-card-text>
                <h2>How do I work?</h2>
                The immune system is incredibly complex, but a major cornerstone
                relies on its ability to obtain a molecule called glutathione.
                Glutathione exists in every cell of your body and is essential
                for life and critical for health. This molecule is constantly
                being used up in dozens of your body’s functions and is
                difficult to replace. Glutathione is your body’s master
                antioxidant. It is a chief enzyme for detoxification, and it is
                literally food for the immune system. Immunocal is a natural
                source of the glutathione precursor cysteine for the maintenance
                of a strong immune system.
              </b-card-text>
            </b-card>
          </b-card-text>
        </b-card>
        <br />
      </b-col>
      <b-col sm="4">
        <br />
        <b-card overlay :img-src="block3">
          <b-card-text>
            <b-card class="text-center" text-variant="black">
              <b-card-text>
                <h2>Some of my benefits are:</h2>
                Maintains a strong immune system. Supports the production of
                antibodies. Helps increase muscle strength and enhance
                performance when combined with regular exercise
              </b-card-text>
            </b-card>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      video1:
        "https://player.vimeo.com/video/703483647?autoplay=1&loop=1&autopause=0&muted=1&title=0&byline=0&portrait=0&controls=0",
      video2:
        "https://player.vimeo.com/video/661099244?autoplay=1&loop=1&autopause=0&muted=1&title=0&byline=0&portrait=0&controls=1",
      block1: "https://cdnwebsite.blob.core.windows.net/home/1.png",
      block2: "https://cdnwebsite.blob.core.windows.net/home/2.png",
      block3: "https://cdnwebsite.blob.core.windows.net/home/3.png",
    };
  },
  components: {
    MixinWrapper,
  },
  mounted: function () {
    //this.$refs.videoRef.play();
  },
};
</script>

<style lang="scss">
@import "./Hero";
</style>