<template>
  <div>
    <LoginComponent />
  </div>
</template>

<script>
import LoginComponent from "@/components/pages/Home/Login";

export default {
  name: "Login",
  components: {
    LoginComponent,
  },
};
</script>