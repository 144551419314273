<template>
    <div>
        <Navbar />
        <router-view />
        <Footer />
    </div>
</template>
<script>
    import Navbar from '@/components/layouts/Navbar/Navbar';
    import Footer from '@/components/layouts/Footer/Footer';

    export default {
        name: 'App',
        components: {
            Navbar,
            Footer
        }
    }
</script>
<style>

    #app {
        overflow: hidden;
    }
</style>
