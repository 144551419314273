<template>
  <div>
    <ScienceComponent />
  </div>
</template>

<script>
import ScienceComponent from "@/components/pages/Home/Science";

export default {
  name: "Science",
  components: {
    ScienceComponent,
  },
};
</script>