<template>
  <MixinWrapper :backgroundColor="'#FFFFFF'"   style="padding-top:3.8rem !important;" id="sport">
    <b-row>
      <b-col sm="12">
          <b-img center fluid :src="banner1" class="w-100" ></b-img>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <br />
        <br />
        <p>
          Benefit from the same nutrition Olympic & World Class athletes use!.
          Whether you’re an endurance athlete, weekend warrior in the gym or on
          the soccer field, legend in your softball league, or an actual
          Olympian – the secret to championship results is better training. And
          the best training can only happen when your body is getting the
          nutrients it needs for peak performance and adequate recovery.
        </p>
        <p>
          Now you can train with the same scientifically-proven performance
          formulas that Olympian and other world-class athletes use. These
          competitors trust Immunotec, and the 45 years of research behind the
          products. Here's a snapshot of just a few elite athletes from around
          the globe who, as Ambassadors, include Immunotec products in their
          training and recovery regimen.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-card-group>
          <b-card
            border-variant="light"
            :img-src="img1"
            img-bottom
             class="no-border mx-auto"    style="max-width: 80%" 
          >
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

     <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-img center fluid class="w-100" :src="img3"></b-img>
      </b-col>
    </b-row>

     <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-img center fluid class="w-100" :src="img4"></b-img>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-img center fluid class="w-100" :src="img5"></b-img>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-img center fluid class="w-100" :src="img6"></b-img>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-img center class="w-100" :src="img7"></b-img>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-img center class="w-100" :src="img8"></b-img>
      </b-col>
    </b-row>


    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-img center fluid class="w-100" :src="img9"></b-img>
      </b-col>
    </b-row>

     <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <b-img center fluid class="w-100" :src="img10"></b-img>
      </b-col>
    </b-row>


    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <br />
        <br />
        <p>
          A number of years ago, Immunotec formed a strategic partnership with a
          company based in Ireland called ORRECO. They provide biomarker
          analysis for world leading athletes, federations and professional
          franchises. ORRECO provides both Immunocal and Immunocal Platinum to
          the multiple programs they serve.
        </p>
        <p>
          Given the increased demand for Immunotec’s products based on the
          multitude of benefits seen by both athletes and coaches, we have taken
          the steps to institute a testing program which would cover all the
          lots of Immunocal and Immunocal Platinum going forward. The testing
          program is designed by LGC* Science for a Safer World. LGC sets the
          standard by which Immunotec gets approval to use the INFORMED-SPORT
          logo on the Immunocal and Immunocal Platinum boxes.
        </p>
      </b-col>
    </b-row>
  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      banner1:
        "https://cdnwebsite.blob.core.windows.net/portfolio/BANNERS-WEB_SPORT_0302.jpg",
      img1: "https://cdnwebsite.blob.core.windows.net/portfolio/BODEGON_HOR_01.jpg",
      img2: "https://static.wixstatic.com/media/8813b9_36a7c29fcf5a44efbacf3e73379baceb~mv2.png/v1/crop/x_0,y_0,w_500,h_467/fill/w_327,h_304,al_c,usm_0.66_1.00_0.01,enc_auto/120sb00.png",
      img3: "https://static.wixstatic.com/media/8813b9_67803e8247a247b4bd46eb5bb3507479~mv2.png/v1/fill/w_980,h_155,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_57%20PM.png",
      img4: "https://static.wixstatic.com/media/8813b9_e451a7d7f8a541be901e5f43e1cd3b10~mv2.png/v1/fill/w_973,h_140,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_20%20PM.png",
      img5: "https://static.wixstatic.com/media/8813b9_b36bb758a3114731a0baf9eb1cc172db~mv2.png/v1/fill/w_980,h_145,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_30%20PM.png",
      img6: "https://static.wixstatic.com/media/8813b9_3ed6cd7a27944b558db618445b9ef974~mv2.png/v1/fill/w_973,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_49%20PM.png",
      img7: "https://static.wixstatic.com/media/8813b9_e513a20625084ebaa603b59777e2340c~mv2.png/v1/fill/w_956,h_145,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_03%20PM.png",
      img8: "https://static.wixstatic.com/media/8813b9_1c0f2eedce704ccda1ff6924ba7a52b7~mv2.png/v1/fill/w_960,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_08_51%20PM.png",
      img9: "https://static.wixstatic.com/media/8813b9_ee3d110f790e4fe584eb5e76d9b38aa0~mv2.png/v1/fill/w_984,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_13%20PM.png",
      img10: "https://static.wixstatic.com/media/8813b9_77ba0f887d7849268958f01f294341a5~mv2.png/v1/fill/w_994,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_40%20PM.png",
    };
  },
  components: {
    MixinWrapper,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>