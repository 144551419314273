<template>
  <MixinWrapper :backgroundColor="'#FFFFFF'"   style="padding-top:3.8rem !important;" id="sport">
    <b-row>
      <b-col sm="12">
          <b-img center fluid :src="banner1" class="w-100" ></b-img>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" class="mx-auto text-center">
        <br />
        <br />
        <p>
         At Immunotec we’re constantly innovating, to produce the most effective wellness products and support our network of Independent Consultants. Our products are widely considered as the preeminent nutritional sustenance for maintaining a healthy immune response. If you care about your wellness, we’ve got you covered.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" class="mx-auto text-center">
       <iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=vc8wmf8unf" width="100%" height="480" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-read; clipboard-write"></iframe>
      </b-col>
    </b-row>

  </MixinWrapper>
</template>

<script>
import MixinWrapper from "@/components/mixin/MixinWrapper";

export default {
  data: function () {
    return {
      banner1:"https://static.wixstatic.com/media/ce0b413e62d04e8abd4afe96b12047fa.jpg/v1/fill/w_1903,h_743,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ce0b413e62d04e8abd4afe96b12047fa.jpg",
      img1: "https://cdnwebsite.blob.core.windows.net/portfolio/BODEGON_HOR_01.jpg",
      img2: "https://static.wixstatic.com/media/8813b9_36a7c29fcf5a44efbacf3e73379baceb~mv2.png/v1/crop/x_0,y_0,w_500,h_467/fill/w_327,h_304,al_c,usm_0.66_1.00_0.01,enc_auto/120sb00.png",
      img3: "https://static.wixstatic.com/media/8813b9_67803e8247a247b4bd46eb5bb3507479~mv2.png/v1/fill/w_980,h_155,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_57%20PM.png",
      img4: "https://static.wixstatic.com/media/8813b9_e451a7d7f8a541be901e5f43e1cd3b10~mv2.png/v1/fill/w_973,h_140,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_20%20PM.png",
      img5: "https://static.wixstatic.com/media/8813b9_b36bb758a3114731a0baf9eb1cc172db~mv2.png/v1/fill/w_980,h_145,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_30%20PM.png",
      img6: "https://static.wixstatic.com/media/8813b9_3ed6cd7a27944b558db618445b9ef974~mv2.png/v1/fill/w_973,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_49%20PM.png",
      img7: "https://static.wixstatic.com/media/8813b9_e513a20625084ebaa603b59777e2340c~mv2.png/v1/fill/w_956,h_145,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_03%20PM.png",
      img8: "https://static.wixstatic.com/media/8813b9_1c0f2eedce704ccda1ff6924ba7a52b7~mv2.png/v1/fill/w_960,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_08_51%20PM.png",
      img9: "https://static.wixstatic.com/media/8813b9_ee3d110f790e4fe584eb5e76d9b38aa0~mv2.png/v1/fill/w_984,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_13%20PM.png",
      img10: "https://static.wixstatic.com/media/8813b9_77ba0f887d7849268958f01f294341a5~mv2.png/v1/fill/w_994,h_144,al_c,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202022-02-21%20at%206_09_40%20PM.png",
    };
  },
  components: {
    MixinWrapper,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>