<template>
  <div>
    <ProductsComponent />
  </div>
</template>

<script>
import ProductsComponent from "@/components/pages/Home/Products";

export default {
  name: "Products",
  components: {
   ProductsComponent,
  },
};
</script>