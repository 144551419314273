<template>
  <div>
    <WellnessComponent />
  </div>
</template>

<script>
import WellnessComponent from "@/components/pages/Home/Wellness";

export default {
  name: "Wellness",
  components: {
    WellnessComponent,
  },
};
</script>