<template>
  <div>
    <LibraryComponent />
  </div>
</template>

<script>
import LibraryComponent from "@/components/pages/Home/Library";

export default {
  name: "Library",
  components: {
    LibraryComponent,
  },
};
</script>