<template>
  <div>
    <ServiceComponent />
  </div>
</template>

<script>
import ServiceComponent from "@/components/pages/Home/Service";

export default {
  name: "Why",
  components: {
    ServiceComponent,
  },
};
</script>