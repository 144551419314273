<template>
  <div>
    <RegisterComponent />
  </div>
</template>

<script>
import RegisterComponent from "@/components/pages/Home/Register";

export default {
  name: "Register",
  components: {
    RegisterComponent,
  },
};
</script>